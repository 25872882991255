import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import queryString from 'query-string';
import { api, ApiResponse } from '../api';
import { SavedImage } from './types';

export const useSavedImages = (
  ids: string[],
  options?: UseQueryOptions<
    ApiResponse<SavedImage[]>,
    unknown,
    ApiResponse<SavedImage[]>,
    ['image', ...string[]]
  >
) =>
  useQuery(
    ['image', ...ids],
    () =>
      api.get<SavedImage[]>(
        `/api/image?${queryString.stringify({ ids }, { arrayFormat: 'bracket' })}`
      ),
    options
  );
