import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { z } from 'zod';
import { api, ApiResponse, validateApi } from 'shared/api';
import { url } from 'shared/url';
import { NewPromo, Promo, promoSchema } from './types';

const usePromoList = (
  options?: UseQueryOptions<ApiResponse<Promo[]>, unknown, ApiResponse<Promo[]>, ['promoList']>
) =>
  useQuery(
    ['promoList'],
    () => api.get<Promo[]>(url('/api/promo')).then(validateApi(z.array(promoSchema))),
    options
  );

const usePromo = (
  slug: string,
  options?: UseQueryOptions<ApiResponse<Promo>, unknown, ApiResponse<Promo>, ['promo', string]>
) => useQuery(['promo', slug], () => api.get<Promo>(`/api/promo/${slug}`), options);

const usePromoAdd = (options?: UseMutationOptions<unknown, unknown, NewPromo>) =>
  useMutation((data: NewPromo) => api.post('/api/promo', JSON.stringify(data)), options);

const usePromoDelete = (options?: UseMutationOptions<unknown, unknown, string>) =>
  useMutation((slug: string) => api.delete(`/api/promo/${slug}`), options);

const usePromoUpdate = (
  options?: UseMutationOptions<ApiResponse<Promo>, unknown, { slug: string; promo: Promo }>
) =>
  useMutation(
    (data: { slug: string; promo: Promo }) =>
      api.put<ApiResponse<Promo>>(`/api/promo/${data.slug}`, JSON.stringify(data.promo)),
    options
  );

export { usePromo, usePromoAdd, usePromoUpdate, usePromoDelete, usePromoList };
