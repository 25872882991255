import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api, ApiResponse } from 'shared/api';

const useWarning = (
  options?: UseQueryOptions<ApiResponse<string>, unknown, ApiResponse<string>, ['warning']>
) => useQuery(['warning'], () => api.get<string>('/api/warning'), options);

const useWarningUpdate = (options?: UseMutationOptions<unknown, unknown, string>) =>
  useMutation(
    ['warning'],
    (warning: string) => api.post('/api/warning', JSON.stringify({ warning })),
    options
  );

export { useWarning, useWarningUpdate };
