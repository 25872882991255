import React from 'react';

import { s3Path } from 'shared/url';
import Image from 'next/image';
import { Banner } from 'entities/banner';

import s from './Banner.module.scss';

type Props = {
  banner: Banner;
};
const BannerComponent = ({ banner: { href, images, linkText, title, isPromoBanner } }: Props) => {
  const mdWebpImage = images.find((image) => image.format === 'webp' && image.size === 'md');

  if (!mdWebpImage) return null;

  return (
    <article className={s.Banner}>
      <Image
        quality={100}
        priority={isPromoBanner}
        alt={title}
        src={s3Path(mdWebpImage.url, true)}
        blurDataURL={mdWebpImage.blurBase64 ?? undefined}
        placeholder={mdWebpImage.blurBase64 ? 'blur' : undefined}
        fill
        className={s.Banner__img}
      />
      <h2 className={s.Banner__title}>{title.toUpperCase()}</h2>
      <a href={href} className={s.Banner__button}>
        {linkText}
      </a>
    </article>
  );
};

export { BannerComponent as Banner };
