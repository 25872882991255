import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { z } from 'zod';
import { api, ApiResponse, validateApi } from 'shared/api';
import { url } from 'shared/url';
import { Banner, bannerSchema, NewBanner } from './types';

const useBanners = (
  options?: UseQueryOptions<ApiResponse<Banner[]>, unknown, ApiResponse<Banner[]>, ['banners']>
) =>
  useQuery(
    ['banners'],
    () => api.get<Banner[]>(url('/api/banner')).then(validateApi(z.array(bannerSchema))),
    options
  );

const useBanner = (
  id: string,
  options?: UseQueryOptions<ApiResponse<Banner>, unknown, ApiResponse<Banner>, string[]>
) =>
  useQuery(['banner', id], () => api.get<Banner>(`/api/banner/${id}`), {
    staleTime: Infinity,
    ...options,
  });

const useBannerAdd = (options?: UseMutationOptions<unknown, unknown, NewBanner>) =>
  useMutation((data: NewBanner) => api.post('/api/banner', JSON.stringify(data)), options);

const useBannerDelete = (options?: UseMutationOptions<unknown, unknown, string>) =>
  useMutation((slug: string) => api.delete(`/api/banner/${slug}`), options);

const useBannerUpdate = (
  options?: UseMutationOptions<ApiResponse<Banner>, unknown, { id: string; banner: Banner }>
) =>
  useMutation(
    (data: { id: string; banner: Banner }) =>
      api.put<ApiResponse<Banner>>(`/api/banner/${data.id}`, JSON.stringify(data.banner)),
    options
  );

export { useBanners, useBannerAdd, useBannerUpdate, useBannerDelete, useBanner };
