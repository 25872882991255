import { z } from 'zod';
import { categorySchema } from 'shared/category';
import { idSchema } from 'shared/id';

export const subcategorySchema = z.object({
  id: idSchema,
  title: z.string(),
  image: z.string().url(),
  category: categorySchema,
  slug: z.string(),
  gender: z.optional(z.boolean()),
  goodsIds: z.array(idSchema),
  _count: z.number(),
});

export type Subcategory = z.infer<typeof subcategorySchema>;

export type NewSubcategory = Omit<Subcategory, 'id' | 'slug'>;
