import React from 'react';
import Image from 'next/image';

import Link from 'next/link';

import { s3Path } from 'shared/url';

import { Promo } from 'entities/promo';

import s from './Promo.module.scss';

type Props = {
  promo: Promo;
};

const PromoComponent = ({ promo: { slug, images, linkText, title, description } }: Props) => {
  const mdWebpImage = images.find((image) => image.format === 'webp' && image.size === 'md');

  if (!mdWebpImage) return null;

  return (
    <article className={s.Promo}>
      <Image
        quality={100}
        priority
        alt={title}
        src={s3Path(mdWebpImage.url, true)}
        blurDataURL={mdWebpImage.blurBase64 ?? undefined}
        placeholder={mdWebpImage.blurBase64 ? 'blur' : undefined}
        fill
        className={s.Promo__img}
      />
      <div className={s.Promo__heading}>
        <h2 className={s.Promo__title}>{title.toUpperCase()}</h2>
        {description && <p className={s.Promo__subtitle}>{description}</p>}
      </div>
      <Link href={`/promo/${slug}`} className={s.Promo__button}>
        {linkText}
      </Link>
    </article>
  );
};

export { PromoComponent as Promo };
