import React from 'react';
import { Modal } from 'antd';
import Link from 'next/link';
import { Button } from 'reakit/Button';

type Props = {
  visible: boolean;
  onCancel: () => void;
};

const AddToCartModal = ({ visible, onCancel }: Props) => (
  <Modal visible={visible} footer={null} bodyStyle={{ fontSize: 24 }} onCancel={onCancel}>
    <p>Товар добавлен в корзину</p>
    <Link href="/cart" className="ant-btn ant-btn-primary ant-btn-lg">
      <Button as="span">Перейти в корзину</Button>
    </Link>
  </Modal>
);

export { AddToCartModal };
