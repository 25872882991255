import { z } from 'zod';

export const savedImageSchema = z.object({
  width: z.number(),
  height: z.number(),
  url: z.string(),
  size: z.enum(['md', 'sm']),
  format: z.enum(['jpeg', 'webp']),
  blurBase64: z.string().optional().nullable(),
});

export type SavedImage = z.infer<typeof savedImageSchema>;
