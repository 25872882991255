import React from 'react';

import { Subcategories, Subcategory } from 'entities/subcategory';
import s from './Section.module.scss';

type Props = {
  title: string;
  subcategories: Subcategory[];
};

const Section = ({ title, subcategories }: Props) => (
  <section className={s.Section}>
    <h2 className={s.Section__header}>{title}</h2>
    <div className={s.Section__subcategories}>
      <Subcategories items={subcategories} rowOnly={false} activeSlug={null} />
    </div>
  </section>
);

export default Section;
