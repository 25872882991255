import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { Alert } from 'antd';
import useMedia from 'use-media';
import { SIZES } from 'shared/sizes';

import img from './info.webp';
import s from './SiteInfo.module.scss';

// typograf-enable
const SiteInfo = () => {
  const isDesktop = useMedia(SIZES.DESKTOP);
  const isTablet = useMedia(SIZES.TABLET);
  return (
    <section className={s.SiteInfo}>
      <div className="SiteInfo__text">
        <h1 className={s.SiteInfo__header}>Магазин очков для зрения и солнцезащитных очков</h1>
        <section className={s.SiteInfo__info}>
          <p>
            При изготовлении очков мы используем самые качественные <Link href="/lens">линзы</Link>{' '}
            из Франции, Японии и Кореи.
          </p>
          <p>
            Наши специалисты проконсультируют вас по любому вопросу, помогут подобрать вам очки в
            соответствии с бюджетом и рецептом.
          </p>
          <br />
          <footer>
            Если у вас возникли вопросы или предложения, напишите нам в соцсети, мессенджерах или по
            телефону{' '}
            <address className={s.SiteInfo__info}>
              <span className={s.nobr}>+7 991 177 3386</span>
            </address>
            .
          </footer>
          <footer>
            <div>
              Ждем вас в нашем магазине по адресу{' '}
              <address>
                <strong className={s.nobr}>ул. Новослободская, 57/65</strong> <br /> м.
                Новослободская, Менделеевская, Савеловская
              </address>
              <br />
              <Link href="/shops">Посмотреть на карте</Link>
            </div>
          </footer>
        </section>
      </div>
      {isDesktop && !isTablet && (
        <div className={s['SiteInfo__img-wrapper']}>
          <Image placeholder="blur" src={img} alt="" fill className={s.SiteInfo__img} />
        </div>
      )}
    </section>
  );
};

export default SiteInfo;
