import { number, z } from 'zod';
import { idSchema } from 'shared/id';
import { savedImageSchema } from 'shared/image';

export const promoSchema = z.object({
  id: idSchema,
  title: z.string(),
  description: z.string().optional().nullable(),
  discount: z.number(),
  images: z.array(savedImageSchema),
  slug: z.string(),
  linkText: z.string(),
  goodsIds: z.array(idSchema),
  fixedPrice: z.number().optional(),
});

export type Promo = z.infer<typeof promoSchema>;

export type NewPromo = Omit<Promo, 'id' | 'slug' | 'image'> & {
  images: string[];
};
