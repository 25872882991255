import React, { useState } from 'react';
import { Button, Form, InputNumber, Modal } from 'antd';

type Props = {
  visible: boolean;
  onSubmit: (sum: number) => void;
  onCancel: () => void;
};

const CustomCertificateModal = ({ visible, onCancel, onSubmit }: Props) => {
  const [sum, setSum] = useState<number | undefined>(undefined);
  const handleSumChange = (value: number) => {
    setSum(value);
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      bodyStyle={{ fontSize: 24, paddingTop: 30 }}
      onCancel={onCancel}
    >
      <Form onFinish={(values) => onSubmit(Number(values.sum))}>
        <Form.Item
          name="sum"
          label="Номинал сертификата"
          rules={[
            { type: 'number', min: 1, max: Infinity, message: 'Введите значение больше 0' },
            { required: true, message: 'Введите значение' },
          ]}
        >
          <InputNumber value={sum} onChange={handleSumChange} placeholder="Сумма" />
        </Form.Item>
        <Button htmlType="submit" role="submit" size="large" type="primary">
          Выбрать
        </Button>
      </Form>
    </Modal>
  );
};

export { CustomCertificateModal };
