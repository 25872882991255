import React, { Suspense } from 'react';
import cn from 'classnames';

import { Banner as BannerComponent } from 'features/banner';
import { Promo as PromoComponent } from 'features/promo';
import { Subcategory } from 'entities/subcategory';
import { SavedImage } from 'shared/image';
import { Banner } from 'entities/banner';
import { Promo } from 'entities/promo';
import Section from './Section/Section';
import { GenderImage } from './GenderImage/GenderImage';
import SiteInfo from './SiteInfo/SiteInfo';
import { Warning } from './Warning/Warning';
import { Certificate } from './Certificate/Certificate';

import s from './Index.module.scss';
import { HowToOrder } from './HowToOrder/HowToOrder';

type Props = {
  subcategories: Subcategory[];
  maleCover: SavedImage | null;
  femaleCover: SavedImage | null;
  banners: Banner[];
  promoList: Promo[];
};

const Index = ({ subcategories, maleCover, femaleCover, banners, promoList }: Props) => (
  <div className={s.IndexPage}>
    <section className={s['IndexPage__image-wrapper']}>
      <Suspense fallback={null}>
        <Warning />
      </Suspense>
    </section>
    <section className={s['IndexPage__image-wrapper']}>
      {promoList.map((promo) => (
        <PromoComponent key={promo.id} promo={promo} />
      ))}
    </section>
    <section className={s['IndexPage__image-wrapper']}>
      {banners
        .filter((banner) => banner.isPromoBanner)
        .map((banner) => (
          <BannerComponent key={banner.id} banner={banner} />
        ))}
    </section>
    <section className={cn(s['IndexPage__image-wrapper'], s['IndexPage__image-wrapper_gender'])}>
      {femaleCover && (
        <GenderImage
          cover={femaleCover}
          title="Для женщин"
          urls={[
            {
              href: '/subcategory/[...slug]',
              as: '/subcategory/rims-zhenskie',
            },
            {
              href: '/subcategory/[...slug]',
              as: '/subcategory/sun-zhenskie',
            },
          ]}
        />
      )}
      {maleCover && (
        <GenderImage
          cover={maleCover}
          title="Для мужчин"
          urls={[
            {
              href: '/subcategory/[...slug]',
              as: '/subcategory/rims-muzhskie',
            },
            {
              href: '/subcategory/[...slug]',
              as: '/subcategory/sun-muzhskie',
            },
          ]}
        />
      )}
    </section>
    <section>
      <Section
        title="Оправы"
        subcategories={subcategories.filter((item) => item.category === 'rims')}
      />
      <Section
        title="Солнцезащитные очки"
        subcategories={subcategories.filter((item) => item.category === 'sun')}
      />
    </section>
    <section className={s['IndexPage__image-wrapper']}>
      {banners
        .filter((banner) => !banner.isPromoBanner)
        .map((banner) => (
          <BannerComponent key={banner.id} banner={banner} />
        ))}
    </section>
    <Certificate />
    <HowToOrder />
    <SiteInfo />
  </div>
);
export default Index;
