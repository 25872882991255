import React from 'react';
import Image, { StaticImageData } from 'next/image';
import s from './HowToOrder.module.scss';

import step1 from './step1.jpg';
import step2 from './step2.jpg';
import step3 from './step3.jpg';
import step4 from './step4.jpg';
import step5 from './step5.jpg';
import step6 from './step6.jpg';

type StepProps = {
  text: string;
  imageSrc: StaticImageData;
};

const steps = [
  [step1, 'Выберите подходящие очки и добавьте их в корзину.'],
  [
    step2,
    'Укажите контактные данные, чтобы мы могли связаться с вами. Если требуется коррекция зрения, укажите в комментарии к заказу свой рецепт.',
  ],
  [
    step3,
    'Завершите заказ. В течение часа консультант свяжется с вами, чтобы обсудить детали заказа — линзы, способ доставки и стоимость.',
  ],
  [
    step4,
    'После подтверждения заказа необходимо внести предоплату 50%. Мы вышлем вам чек-подтверждение заказа.',
  ],
  [
    step5,
    'Нам потребуется до 3-х дней, чтобы изготовить, после чего мы доставим ваш заказ или вы самостоятельно сможете забрать его в оптике.',
  ],
  [step6, 'В подарок к каждому заказу мы кладем мягкий чехол и салфетку для протирки очков.'],
] as const;
const Step = ({ text, imageSrc }: StepProps) => (
  <div className={s.HowToOrder__step}>
    <Image
      className={s.HowToOrder__image}
      placeholder="blur"
      alt={text}
      src={imageSrc}
      loading="lazy"
    />

    {text}
  </div>
);

export const HowToOrder = () => (
  <div className={s.HowToOrder}>
    <h2>Как заказать очки</h2>
    <div className={s.HowToOrder__steps}>
      {steps.map(([src, text]) => (
        <Step key={text} text={text} imageSrc={src} />
      ))}
    </div>
  </div>
);
