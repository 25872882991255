import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { SavedImage } from 'shared/image';
import { s3Path } from 'shared/url';

import s from './GenderImage.module.scss';

type Url = {
  href: string;
  as: string;
};

type Props = {
  cover: SavedImage;
  title: string;
  urls: [Url, Url];
};
export const GenderImage = ({ cover, title, urls }: Props) => (
  <article className={s.GenderImage}>
    <Image
      quality={90}
      priority
      alt=""
      fill
      src={s3Path(cover.url, true)}
      className={s.GenderImage__img}
      sizes="(max-width: 767px) 100vw, 50vw"
      placeholder={cover.blurBase64 ? 'blur' : undefined}
      blurDataURL={cover.blurBase64 ?? undefined}
    />
    <h2 className={s.GenderImage__title}>{title.toUpperCase()}</h2>
    <div className={s.GenderImage__actions}>
      <Link href={urls[0].href} as={urls[0].as} className={s.GenderImage__button}>
        <span>Оправы</span>
      </Link>
      <Link href={urls[1].href} as={urls[1].as} className={s.GenderImage__button}>
        <span>Солнце</span>
      </Link>
    </div>
  </article>
);
