import React, { useState } from 'react';
import { useCartItems, createCertificate } from 'features/cartItems';
import { numberWithSpaces } from 'shared/format';
import { AddToCartModal } from 'features/addCartObjectSuccess';
import { CustomCertificateModal } from 'features/customCertificateModal';
import { sendMetrikaEvent } from 'entities/metrika';
import Image from 'next/image';
import certificate from './certificate.webp';

import s from './Certificate.module.scss';

type Modal = 'not_visible' | 'custom_sum' | 'order_success';

const Certificate = () => {
  const { cartItemsStorage } = useCartItems();
  const [modal, setModal] = useState<Modal>('not_visible');

  const handleBuyCertificate = (sum: number) => {
    setModal('order_success');
    cartItemsStorage.addObjectToCart(createCertificate(sum));
    sendMetrikaEvent('addToCartCert');
  };

  const isOrderSuccessModal = modal === 'order_success';
  const isCustomSumModal = modal === 'custom_sum';

  return (
    <section className={s.Certificate}>
      <h2 className={s.Certificate__heading}>Подарочные сертификаты</h2>
      <p className={s.Certificate__text}>
        Радуйте ваших близких и себя подарочными сертификатами от нашей оптики.
      </p>

      <div className={s['Certificate__img-wrapper']}>
        <Image
          placeholder="blur"
          alt="Сертификат"
          src={certificate}
          className={s.Certificate__img}
          loading="lazy"
        />

        <section className={s.Certificate__buttons}>
          <h3 className={s.Certificate__buttonsHeading}>Выберите номинал</h3>
          <button className={s.Certificate__button} onClick={() => handleBuyCertificate(5000)}>
            {numberWithSpaces(5000)}&nbsp;&#8381;
          </button>
          <button className={s.Certificate__button} onClick={() => handleBuyCertificate(10000)}>
            {numberWithSpaces(10000)}&nbsp;&#8381;
          </button>

          <button className={s.Certificate__button} onClick={() => setModal('custom_sum')}>
            Другая сумма
          </button>
        </section>
      </div>
      {isOrderSuccessModal && (
        <AddToCartModal visible={isOrderSuccessModal} onCancel={() => setModal('not_visible')} />
      )}
      {isCustomSumModal && (
        <CustomCertificateModal
          onSubmit={handleBuyCertificate}
          visible={isCustomSumModal}
          onCancel={() => setModal('not_visible')}
        />
      )}
    </section>
  );
};

export { Certificate };
