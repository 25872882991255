import { z } from 'zod';
import { idSchema } from 'shared/id';
import { savedImageSchema } from 'shared/image';

export const bannerSchema = z.object({
  id: idSchema,
  title: z.string(),
  href: z.string().url(),
  description: z.string().optional().nullable(),
  images: z.array(savedImageSchema),
  video: z.string().optional().nullable(),
  linkText: z.string(),
  isPromoBanner: z.boolean().optional(),
});

export type Banner = z.infer<typeof bannerSchema>;

export type NewBanner = Omit<Banner, 'id'>;
