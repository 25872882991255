import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { z } from 'zod';
import { api, ApiResponse, validateApi } from 'shared/api';
import { url } from 'shared/url';
import { NewSubcategory, Subcategory, subcategorySchema } from './types';

const useSubcategories = (
  options?: UseQueryOptions<
    ApiResponse<Subcategory[]>,
    unknown,
    ApiResponse<Subcategory[]>,
    ['subcategories']
  >
) =>
  useQuery(
    ['subcategories'],
    () =>
      api.get<Subcategory[]>(url('/api/subcategory')).then(validateApi(z.array(subcategorySchema))),
    options
  );

const useSubcategory = (
  slug: string,
  options?: UseQueryOptions<
    ApiResponse<Subcategory>,
    unknown,
    ApiResponse<Subcategory>,
    ['subcategory', string]
  >
) =>
  useQuery(['subcategory', slug], () => api.get<Subcategory>(`/api/subcategory/${slug}`), options);

const useSubcategoriesAdd = (options?: UseMutationOptions<unknown, unknown, NewSubcategory>) =>
  useMutation(
    (data: NewSubcategory) => api.post('/api/subcategory', JSON.stringify(data)),
    options
  );

const useSubcategoriesDelete = (options?: UseMutationOptions<unknown, unknown, string>) =>
  useMutation((slug: string) => api.delete(`/api/subcategory/${slug}`), options);

const useSubcategoryUpdate = (
  options?: UseMutationOptions<
    ApiResponse<Subcategory>,
    unknown,
    { slug: string; subcategory: Subcategory }
  >
) =>
  useMutation(
    (data: { slug: string; subcategory: Subcategory }) =>
      api.put<ApiResponse<Subcategory>>(
        `/api/subcategory/${data.slug}`,
        JSON.stringify(data.subcategory)
      ),
    options
  );

export {
  useSubcategories,
  useSubcategoriesAdd,
  useSubcategoriesDelete,
  useSubcategory,
  useSubcategoryUpdate,
};
