import React from 'react';
import { useWarning } from 'entities/warning';

import s from './Warning.module.scss';

const Warning = () => {
  const { data: { data: warning = '' } = {} } = useWarning({ staleTime: Infinity });
  return warning ? <div className={s.Warning}>{warning}</div> : null;
};

export { Warning };
