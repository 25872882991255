import React, { useEffect, useRef } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import Image from 'next/image';

import { s3Path } from 'shared/url';
import { Subcategory } from '../../types';

import s from './Subcategories.module.scss';

type Props = {
  items: Subcategory[];
  activeSlug: string | null;
  rowOnly: boolean;
};

const Subcategories = ({ items, activeSlug, rowOnly = false }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.querySelector(`.${s.Subcategories_active}`)?.scrollIntoView({ block: 'center' });
    }
  }, [activeSlug]);

  return (
    <div ref={ref} className={cn(s.Subcategories, { [s['Subcategories_row-only']]: rowOnly })}>
      {items.map((item, index) => (
        <section
          key={item.slug}
          className={s.Subcategories__section}
          data-testid={`Subcategory.${index}`}
        >
          <Link href={`/subcategory/${item.slug}`} className={cn(s.Subcategory, 'not-focus')}>
            <Image
              height="73"
              width="130"
              loading="lazy"
              quality={100}
              className={cn(s.Subcategory__image, {
                [s.Subcategories_active]: activeSlug === item.slug,
              })}
              src={s3Path(item.image, true)}
              alt={item.title}
            />
            <h3 className={s.Subcategory__link}>{item.title}</h3>
          </Link>
        </section>
      ))}
    </div>
  );
};

export { Subcategories };
